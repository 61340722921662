@import url("https://fonts.googleapis.com/css?family=Gruppo");

.logo {
	padding-top: 100px;
	padding-bottom: 50px;
}

.title h1 {
	font-family: 'Gruppo';
	font-weight: 1000;
	font-size: 50px;
}

.interestFormQr h1 {
	font-family: 'Gruppo';
	font-weight: 1000;
	font-size: 60px;
	text-align: center;
}

.interestFormQr h2 {
	font-family: 'Gruppo';
	font-weight: 1000;
	font-size: 40px;
	padding-top: 15px;
	text-align: center;
}

.interestFormQr img {
  padding-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.interestFormQr {
	padding-top: 20px;
	padding-bottom: 100px;
}

.title {
	padding-bottom: 30px;
}

.title-centered {
	font-family: 'Gruppo';
	font-weight: 8000;
	font-size: 35px;
	text-align: center;
	padding-bottom: 30px;
	color: #f21131;
}

.bodyText h2 {
	font-family: 'Gruppo';
	font-weight: 1000;
	font-size: 35px;
	padding-top: 15px;
}

.bodyText li {
	font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
	font-size: 15px;
	padding-bottom:5px;
}

.bodyText p {
	font-family:'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
	font-size: 18px;
}

.bodyText {
	padding-bottom: 20px;
}

.bodyText Link {
	font-family: 'Gruppo';
	font-weight: 800;
	font-size: 20px;
	color: #f21131;
	text-transform: uppercase;
}
.bodyText Link:hover {
	color: #C30000;
	text-transform: uppercase;
	text-decoration: none;
}

.white-text {
	color: #fff;
}
.white-text:hover {
	color: #fff;
	text-decoration: none;
}

.buttonBlock .btn {
	background-color: #f21131;
	color: #fff;
	border-color: #f21131;
	font-family: 'Gruppo';
	font-weight: 800;
	font-size: 20px;
	text-transform: uppercase;
}

.buttonBlock .btn:hover {
	background-color: #C30000;
	border-color: #C30000;
	color: #fff;
	font-family: 'Gruppo';
	font-weight: 800;
	font-size: 20px;
	text-transform: uppercase;
}

.buttonBlock {
	padding-bottom: 40px;
}

